import React from 'react';
import ReactDOM from 'react-dom';
import { FullSchedule } from './epg/schedule';
import imported from './epg/schedule.json';
import { Schedules } from './epg/types';
import './epg/global.css';
import './epg/styles.css';

const schedules = (imported as any) as Schedules;

ReactDOM.render(
  <FullSchedule date={'20210308'} schedules={schedules} />,
  document.getElementById('root')
);
