import React, { FC, useRef } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { Cell, Epg } from './epg';

import { addHours, differenceInMinutes, startOfDay } from 'date-fns';
import { Channel, Schedule, Schedules } from './types';
import useIntersectionObserver from './useIntersectionObserver';

export const FullSchedule: FC<{ date: string; schedules: Schedules }> = ({
  date,
  schedules,
}) => {
  const byLengthAndAlphabetically = (a: string, b: string) => {
    return (
      a.length - b.length || // sort by length, if equal then
      a.localeCompare(b) // sort by dictionary order
    );
  };
  const channelKeys = [
    ...Object.keys(schedules).sort(byLengthAndAlphabetically),
  ];
  const numHours = 24;

  const first = startOfDay(parseISO(date));

  const columnHeaders = [];
  let hour = first;
  for (let i = 0; i < numHours; i++) {
    const date = format(hour, 'HH:mm dd MMM');
    columnHeaders.push(() => date);
    hour = addHours(hour, 1);
  }

  const rowHeaders = channelKeys.map((key) => {
    const network = schedules[key].channel;
    return () => <NetworkHeader network={network} />;
  });

  const rowCells: Cell[][] = channelKeys.map((key) => {
    const sched = schedules[key].schedule;
    return sched.map((s) => {
      const runtime = s.len;
      const start = parseISO(s.t);
      const minutes = differenceInMinutes(start, first);
      const col = minutes / 60;
      let size = runtime / 60;

      let runsPastMidnight = false;
      // todo - magic number
      let minutesInDay = 24 * 60;
      if (minutes + runtime > minutesInDay) {
        runsPastMidnight = true;
        size = (minutesInDay - minutes) / 60;
      }

      return {
        column: col,
        size: size,
        render: () => (
          <CellDisplay schedule={s} runsPastMidnight={runsPastMidnight} />
        ),
      };
    });
  });

  return (
    <div className="App">
      <Epg
        columnHeaders={columnHeaders}
        rowHeaders={rowHeaders}
        rowCells={rowCells}
      />
    </div>
  );
};

const CellDisplay: FC<{ schedule: Schedule; runsPastMidnight: boolean }> = ({
  schedule,
  runsPastMidnight,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isVisible] = useIntersectionObserver({
    elementRef: ref,
  });

  return (
    <div
      ref={ref}
      className={`Cell ${runsPastMidnight ? 'RunsPastMidnight' : ''}`}
    >
      <>
        {isVisible && schedule.len >= 30 && <Image schedule={schedule} />}
        <div className="Details">
          <div className="Name" title={schedule.n}>
            {schedule.n}
          </div>
          <div className="SubName" title={schedule.ep}>
            {schedule.ep}
          </div>
        </div>
      </>
    </div>
  );
};

const toSrc = (url: string) => {
  const prefix = `https://json.schedulesdirect.org/20141201/image/`;
  const isComplete = url.startsWith('http');
  return isComplete ? url : `${prefix}${url}`;
};

const Image: FC<{ schedule: Schedule }> = ({ schedule }) => {
  if (schedule.i && schedule.i.length > 0) {
    const src = toSrc(schedule.i[0].u);
    return <img className={`a${schedule.i[0].a}`} src={src} alt="" />;
  }
  if (schedule.img) {
    const src = toSrc(schedule.img);
    return <img className="a4x3" src={src} alt="" />;
  }
  return null;
};

const NetworkHeader: FC<{ network: Channel }> = ({ network }) => {
  return (
    <div className="NetworkHeader">
      {network?.img && (
        // <img src={`http://xmltv.co.uk${network?.img}`} alt={network?.n} title={network?.n}/>
        <img src={`${network?.img}`} alt={network?.n} title={network?.n} />
      )}
      {!network?.img && <div className="NetworkName">{network?.n}</div>}
      {network?.num && <div className="NetworkNumber">{network?.num}</div>}
    </div>
  );
};
