import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import useIntersectionObserver from './useIntersectionObserver';

type Position = { x: number; y: number };

const useDrag = (): [
  React.RefObject<HTMLDivElement>,
  (e: React.MouseEvent) => void
] => {
  const [, setPosition] = useState<Position>({ x: 0, y: 0 });
  const [isDragging, setDragging] = useState<boolean>(false);

  const scrollRef: React.RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(
    null
  );

  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
    setPosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    const onMouseUp = () => {
      if (isDragging) {
        setDragging(false);
      }
    };

    const onMouseMove = (event: MouseEvent) => {
      if (isDragging) {
        const scrollNode = scrollRef.current;
        if (scrollNode) {
          setPosition((previousPosition) => {
            const { clientX, clientY } = event;
            const { x, y } = previousPosition;
            const { scrollLeft, scrollTop } = scrollNode;
            const newX = scrollLeft - (clientX - x);
            const newY = scrollTop - (clientY - y);
            scrollNode.scrollTo(newX, newY);
            return {
              x: clientX,
              y: clientY,
            };
          });
        }
      }
    };

    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [isDragging]);

  return [scrollRef, onMouseDown];
};

export const Epg: FC<{
  rowCells: Cell[][];
  columnHeaders: (() => ReactElement | string)[];
  rowHeaders: (() => ReactElement | string)[];
}> = ({ rowCells, columnHeaders, rowHeaders, children }) => {
  const [scrollRef, onMouseDown] = useDrag();

  const columnWidth = '16rem'; //TODO - needs to match css
  const width = `calc(${columnWidth} * ${columnHeaders.length})`;

  const cheaders = [];
  for (let i = 0; i < columnHeaders.length; i++) {
    cheaders.push(
      <div
        data-type="column-header"
        className="column-header"
        key={`column-header-${i}`}
        style={{
          width: columnWidth,
        }}
      >
        {columnHeaders[i]()}
      </div>
    );
  }

  const rheaders = [];
  for (let i = 0; i < rowHeaders.length; i++) {
    rheaders.push(
      <div
        data-type="row-header"
        className="row-header"
        key={`row-header-${i}`}
      >
        {rowHeaders[i]()}
      </div>
    );
  }

  const cs = rowCells.map((cells, i) => {
    const row = cells.map((cell, index) => {
      return (
        <CellDisplay
          key={`cell-${index}`}
          cell={cell}
          columnWidth={columnWidth}
        />
      );
    });
    return (
      <RowDisplay key={`row-${i}`} width={width}>
        {row}
      </RowDisplay>
    );
  });

  return (
    <div className="epg">
      {/*<div className="corner" />*/}
      <div className="scrollable" ref={scrollRef} onMouseDown={onMouseDown}>
        <div className="outer-pane">
          <div className="column-headers">{cheaders}</div>
          <div className="body-pane">
            <div className="row-headers">{rheaders}</div>
            <div className="content">{cs}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export type Cell = {
  column: number;
  size: number;
  render: () => ReactElement | string;
};

const CellDisplay: FC<{
  cell: Cell;
  columnWidth: string;
}> = ({ cell, columnWidth }) => {
  return (
    <div
      data-type="cell"
      className="CellDisplay"
      style={{
        left: `calc(${columnWidth} * ${cell.column})`,
        width: `calc(${columnWidth} * ${cell.size})`,
      }}
    >
      {cell.render()}
    </div>
  );
};

const RowDisplay: FC<{ width: string }> = ({ width, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isVisible] = useIntersectionObserver({
    elementRef: ref,
  });

  return (
    <div data-type="row" ref={ref} className="row" style={{ width: width }}>
      {isVisible && <>{children}</>}
    </div>
  );
};
